<div class="d-block d-md-none" *ngIf="totalPages > 1">
  <!--small view devices-->
  <div class="d-flex text-center my-2">
    <div class="w-25 p-1">
      <button
        class="btn btn-secondary w-100"
        type="button"
        [disabled]="previous === 0"
        (click)="
          previous = previous - pageSize;
          pageNum = pageNum - 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-left"></i>
      </button>
    </div>
    <div class="w-50 p-1 d-flex justify-content-center align-items-center">
      @if(totalPages >1){
      <form [formGroup]="pageForm">
        <input
          type="tel"
          list="numbers"
          class="form-control number-input"
          [min]="1"
          [max]="totalPages"
          formControlName="pageNum"
        />
      </form>

      <datalist id="numbers">
        <option *ngFor="let number of pageNumbers" [value]="number">
          {{ number }}
        </option>
      </datalist>
      <!-- <select
        class="form-control me-2 w-auto"
        [(ngModel)]="pageNum"
        (change)="previous = (pageNum - 1) * pageSize; changePagination()"
      >
        <option *ngFor="let number of pageNumbers" [value]="number">
          {{ number }}
        </option>
      </select> -->
      }@else {
      {{ pageNum }}
      } of {{ totalPages }}
    </div>
    <div class="w-25 p-1">
      <button
        class="btn btn-secondary w-100"
        type="button"
        [disabled]="previous + pageSize >= totalRows"
        (click)="
          previous = previous + pageSize;
          pageNum = pageNum + 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
<div class="d-none d-md-block">
  <!--large view devices-->

  <div class="d-flex justify-content-end align-items-center my-2">
    <div class="me-2">Items per page</div>
    <select
      class="form-control me-2 w-auto"
      [(ngModel)]="pageSize"
      (change)="previous = 0; pageNum = 1; changePagination()"
    >
      <option
        *ngFor="let paginationval of paginationValues"
        [value]="paginationval"
      >
        {{ paginationval }}
      </option>
    </select>
    <ng-container *ngIf="totalPages > 1">
      <button
        class="btn btn-secondary me-2"
        [disabled]="previous === 0"
        (click)="
          previous = previous - pageSize;
          pageNum = pageNum - 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-left"></i>
      </button>

      @if(totalPages >1){

      <form [formGroup]="pageForm">
        <input
          type="tel"
          list="numbers"
          class="form-control number-input"
          [min]="1"
          [max]="totalPages"
          formControlName="pageNum"
        />
      </form>

      <datalist id="numbers">
        <option *ngFor="let number of pageNumbers" [value]="number">
          {{ number }}
        </option>
      </datalist>

      <!-- <select
        class="form-control me-2 w-auto"
        [(ngModel)]="pageNum"
        
      >
        <option *ngFor="let number of pageNumbers" [value]="number">
          {{ number }}
        </option>
      </select> -->
      }@else {
      {{ pageNum }}
      } of {{ totalPages }}

      <button
        class="btn btn-secondary ms-2"
        [disabled]="previous + pageSize >= totalRows"
        (click)="
          previous = previous + pageSize;
          pageNum = pageNum + 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-right"></i>
      </button>
    </ng-container>
  </div>
</div>
