import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'gtapp-pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrl: './pagination-control.component.scss',
})
export class PaginationControlComponent implements OnInit {
  @Input() pageSize: any; //How many entries the page will show
  @Input() pageNum: any; //In which page the user is now
  @Input() previous: any; //How many entries we have to laeave or in previous page
  @Input() totalRows: any; // Total data in the backend
  totalPages: number = 0; // Calculate the pages i.e how many pages will be there

  @Output() onChangePagination = new EventEmitter(); //On change the size of page from dropdown

  paginationValues = [5, 10, 15, 20, 25];
  pageNumbers: any = [];
  pageNumOnLast: number = 0;
  pageForm: any = new UntypedFormGroup({
    pageNum: new UntypedFormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(this.totalPages),
    ]),
  });
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.totalPages = Math.ceil(this.totalRows / this.pageSize);
    this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);

    this.pageForm
      .get('pageNum')
      .setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(this.totalPages),
      ]);
    this.pageForm.get('pageNum').updateValueAndValidity();
  }

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.totalRows / this.pageSize);
    this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    this.pageForm.get('pageNum').valueChanges.subscribe((value: any) => {
      if (typeof value === 'string') {
        this.validatePageNumber(value);
      }
    });
  }
  changePagination() {
    this.onChangePagination.emit({
      previous: Number(this.previous),
      pageNum: Number(this.pageNum),
      pageSize: Number(this.pageSize),
    });
    this.pageForm.patchValue({ pageNum: this.pageNum }, { emitEvent: false });
  }

  validatePageNumber(value: string) {
    let numericValue = value?.replace(/\D/g, '');
    if (numericValue) {
      let pageNum = parseInt(numericValue, 10);
      if (pageNum < 1) {
        this.pageNum = 1;
      } else if (pageNum > this.totalPages) {
        this.pageNum = this.totalPages;
      } else {
        this.pageNum = pageNum;
      }
      this.pageForm.patchValue({ pageNum: this.pageNum }, { emitEvent: false });
      if (this.pageNum !== this.pageNumOnLast) {
        this.previous = (this.pageNum - 1) * this.pageSize;
        this.changePagination();
      }
      this.pageNumOnLast = this.pageNum;
    }
  }
}
