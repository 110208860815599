import { formatDate } from '@angular/common';

const formatDateWithoutTime = (date: any) => {
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};
function setTime(date: Date, time: string) {
  const [hours, minutes] = time?.split(':');
  date.setHours(parseInt(hours), parseInt(minutes));
}
export function getJobStatus(scheduleData: any, date: any) {
  let userDefinedStatus =
    formatDateWithoutTime(new Date(date)) >= formatDateWithoutTime(new Date())
      ? scheduleData?.job_details?.schedule_details?.status?.name
        ? scheduleData?.job_details?.schedule_details?.status?.name
        : date
        ? scheduleData?.job_details?.schedule_details?.[
            formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
          ]?.status?.name
        : ''
      : '';
  const today =
    formatDateWithoutTime(new Date(date)) === formatDateWithoutTime(new Date());
  if (!userDefinedStatus) {
    let startDateTime: any = new Date(date);
    let endDateTime: any = new Date(date);

    if (scheduleData?.repeat_type == 'once') {
      startDateTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
      endDateTime = new Date(
        scheduleData?.end_day + ' ' + scheduleData?.end_time
      );
    } else {
      setTime(startDateTime, scheduleData?.start_time);
      setTime(endDateTime, scheduleData?.end_time);
    }
    if (startDateTime.getTime() > endDateTime.getTime()) {
      // overnight schedule ; change enddate
      endDateTime.setDate(endDateTime.getDate() + 1);
    }
    endDateTime = endDateTime.getTime();
    startDateTime = startDateTime.getTime();

    const currentDateTime = new Date().getTime();
    // check whether the shift has ended
    // if (currentDateTime > endDateTime) {
    //   userDefinedStatus = 'Shift Complete';
    if (today) {
      if (currentDateTime > startDateTime) {
        const assigneeStatus = getEventAssigneeList(scheduleData, date) || [];
        const isClockedIn = assigneeStatus?.some(
          (assignee: any) => assignee?.clocked_in_at
        );
        return isClockedIn ? 'In Progress' : 'Late to Shift';
      } else {
        userDefinedStatus = 'Not Started';
      }
    }
  }

  return userDefinedStatus;
}
export function getJobServiceType(scheduleData: any, date: any) {
  return formatDateWithoutTime(new Date(date)) >=
    formatDateWithoutTime(new Date())
    ? scheduleData?.job_details?.schedule_details?.service_type
      ? scheduleData?.job_details?.schedule_details?.service_type
      : date
      ? scheduleData?.job_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.service_type
      : ''
    : '';
}
export function getEventAssignees(
  scheduleData: any,
  date: any,
  isAdminDispatcher: any
) {
  return isAdminDispatcher ? getEventAssigneeList(scheduleData, date) : [];
}

export function getEventAssigneeList(scheduleData: any, date: any) {
  return scheduleData?.patrol_route_details
    ? scheduleData?.patrol_route_details?.schedule_details?.assignee_data
      ? scheduleData?.patrol_route_details?.schedule_details?.assignee_data
      : date
      ? scheduleData?.patrol_route_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.assignee_data
      : []
    : scheduleData?.job_details?.schedule_details?.assignee_data
    ? scheduleData?.job_details?.schedule_details?.assignee_data
    : date
    ? scheduleData?.job_details?.schedule_details?.[
        formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
      ]?.assignee_data
    : [];
}
export function trashIconIf(
  scheduleData: any,
  date: any,
  isAdminDispatcher: any
) {
  if (!isAdminDispatcher) {
    return false;
  }
  let startTime = new Date(date);
  if (scheduleData?.repeat_type == 'once') {
    startTime = new Date(
      scheduleData?.start_day + ' ' + scheduleData?.start_time
    );
  } else {
    setTime(startTime, scheduleData?.start_time);
  }
  return Boolean(startTime > new Date());
}
