import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { from, lastValueFrom, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { gTDB } from '../../../shared/services/db';
import { DeviceInfoService } from '../../../shared/services/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService,
    private swPush: SwPush
  ) {}

  async addCompany(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/company/', body)
        .pipe(map((response: any) => response))
    );
  }
  async updateCompanyDetails(requestData?: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/company_profile/', body, { params: params })
        .pipe(map((response) => response))
    );
  }
  async addCompanyLicense(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .post('/api/company_profile/add_subscriber_license/', body)
      .pipe(map((response) => response));
  }

  async updateCompanyDetailsById(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/company/${requestData.id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async enableDisableCompanyById(id: any) {
    let body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .patch(`/api/company/${id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async enableDisableSiteById(id: any) {
    let body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .patch(`/api/site/${id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async addCustomerContactById(requestData: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/company/${requestData.id}/add_contact/`, body)
        .pipe(map((response) => response))
    );
  }
  async addSiteContactById(requestData: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/site/${requestData.id}/add_contact/`, body)
        .pipe(map((response) => response))
    );
  }
  fetchCustomerList(params?: any) {
    return this.http
      .get('/api/company/', { params: params })
      .pipe(map((response) => response));
  }
  getCompanyDetailById(id: any, params?: any) {
    return this.http.get(`/api/company/${id}/`, { params: params });
  }
  getCustomerContacts(id: any) {
    return this.http.get(`/api/company/${id}/contacts/`);
  }
  async updateClient(id: any, body: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/company/${id}/update/`, body, { params: params })
        .pipe(map((response) => response))
    );
  }
  async updateSite(id: any, body: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/site/${id}/update/`, body, { params: params })
        .pipe(map((response) => response))
    );
  }

  getCustomerReport(id: any, requestData: any) {
    return this.http.post(`/api/company/${id}/generate_report/`, requestData);
  }
  getSiteContacts(id: any, params?: any) {
    return this.http.get(`/api/site/${id}/contacts/`, { params: params });
  }
  searchLocationContacts(params: any) {
    return this.http.get(`/api/contacts/`, { params: params });
  }

  updateLocationContact(id: any, body: any, params?: any) {
    return this.http
      .put(`/api/contacts/${id}/`, body, { params: params })
      .pipe(map((response) => response));
  }
  searchSites(params?: any) {
    const searchString = params?.search_str;

    return from(this.fetchClientORSitesFromIndexedDb(searchString, true)).pipe(
      switchMap((dataFromIndexedDb: any) => {
        if (dataFromIndexedDb && dataFromIndexedDb?.length > 0) {
          return of({ status: 'success', data: dataFromIndexedDb });
        } else {
          return this.http
            .get('/api/site/', { params: params })
            .pipe(map((response) => response));
        }
      })
    );
  }
  getSiteList(params: any) {
    return this.http.get(`api/site/`, { params: params });
  }
  searchClients(searchString: any) {
    return from(this.fetchClientORSitesFromIndexedDb(searchString)).pipe(
      switchMap((dataFromIndexedDb: any) => {
        if (dataFromIndexedDb && dataFromIndexedDb?.length > 0) {
          return of({ status: 'success', data: dataFromIndexedDb });
        } else {
          return this.http
            .get('/api/company/', { params: { search_str: searchString } })
            .pipe(map((response) => response));
        }
      })
    );
  }

  async addSiteInClient(requestData: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.post(`/api/site/`, body).pipe(map((response) => response))
    );
  }
  async updateSiteById(requestData: any, id: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.put(`api/site/${id}/`, body).pipe(map((response) => response))
    );
  }
  getSiteById(id: any) {
    return this.http.get(`api/site/${id}/`);
  }
  async deleteClientContact(id: any, contactId: any, contact_type: any) {
    let body = {
      contact_id: contactId,
      contact_type: contact_type,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.put(`api/company/${id}/delete_contact/`, body)
    );
  }
  async deleteSiteContact(id: any, contactId: any, contact_type: any) {
    let body = {
      contact_id: contactId,
      contact_type: contact_type,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(this.http.put(`api/site/${id}/delete_contact/`, body));
  }
  async deleteLicense(id: any, is_subscriber_license: any) {
    const body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.post(`api/security_license/${id}/delete_license/`, body, {
        params: { is_subscriber: is_subscriber_license },
      })
    );
  }
  editLocationContact(id: any, params?: any) {
    return this.http.get(`api/contacts/${id}/`, {
      params: params,
    });
  }

  downloadTemplateFile() {
    const downloadHttpOption = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/api/file_import/`, downloadHttpOption);
  }

  //Monitoring Companies related APIs
  fetchMonitoringCompanyList(params?: any) {
    return this.http
      .get('/api/monitoring_company/', { params: params })
      .pipe(map((response) => response));
  }
  fetchMonitoringCompanyById(id?: any) {
    return this.http
      .get(`/api/monitoring_company/${id}/`)
      .pipe(map((response) => response));
  }
  async createMonitoringCompany(requestData?: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/monitoring_company/', body)
        .pipe(map((response) => response))
    );
  }
  async updateMonitoringCompanyDetailById(requestData: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/monitoring_company/${requestData.id}/`, body, {
          params: params,
        })
        .pipe(map((response) => response))
    );
  }
  async deleteMonitoringCompany(id: any, params?: any) {
    const body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.put(`api/monitoring_company/${id}/`, body, {
        params: params,
      })
    );
  }

  //Response Type related APIs
  fetchResponseTypeList(params?: any) {
    return this.http
      .get('/api/response_type/', { params: params })
      .pipe(map((response) => response));
  }
  async createResponseType(requestData?: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/response_type/', body)
        .pipe(map((response) => response))
    );
  }
  async updateResponseTypeDetailById(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/response_type/${requestData.id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async deleteResponseType(id: any, params?: any) {
    const body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.put(`api/response_type/${id}/`, body, {
        params: params,
      })
    );
  }
  addContactSubscriberUser(requestData: any) {
    const body = {
      ...requestData.data,
    };
    return this.http
      .post(`/api/contacts/`, body)
      .pipe(map((response) => response));
  }
  async fetchClientORSitesFromIndexedDb(
    searchStr: string,
    isSite: boolean = false
  ) {
    const path = isSite
      ? '/api/site/list_all_sites/'
      : '/api/company/list_all_companies/';
    return new Promise(async (resolve) => {
      if (this.swPush.isEnabled) {
        try {
          const indexedDbData: any = await gTDB.cachedData
            .where({ shortPath: path })
            .toArray();

          const resultData: any = indexedDbData?.[0]?.data || [];

          if (!searchStr) resolve(resultData);

          const filteredList = resultData?.filter(
            (item: any) =>
              item?.company_name
                ?.toLowerCase()
                .includes(searchStr?.toLowerCase()) ||
              item?.address?.full_address
                ?.toLowerCase()
                .includes(searchStr?.toLowerCase()) ||
              item?.company?.company_name
                ?.toLowerCase()
                .includes(searchStr?.toLowerCase())
          );

          resolve(filteredList);
        } catch (error) {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }

  //Service Type related APIs
  fetchServiceTypeList(params?: any) {
    return this.http
      .get('/api/service_type/', { params: params })
      .pipe(map((response) => response));
  }
  async createServiceType(requestData?: any, params?: any) {
    const body = {
      ...requestData,
    };
    return lastValueFrom(
      this.http
        .post('/api/service_type/', body)
        .pipe(map((response) => response))
    );
  }
  async updateServiceTypeDetailById(requestData: any) {
    const body = {
      ...requestData,
    };
    return lastValueFrom(
      this.http
        .put(`/api/service_type/${requestData.id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async deleteServiceType(id: any, params?: any) {
    const body = {};
    return lastValueFrom(
      this.http.put(`api/service_type/${id}/`, body, {
        params: params,
      })
    );
  }
}
