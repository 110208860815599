@if(mCompanyDetailData?.id ||mcId){
<ng-container>
  <div class="details-card mb-4">
    <div class="col-12 col-lg-6">
      <dl>
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <dt>Company Name</dt>
            <dd>{{ mCompanyDetailData?.company_name }}</dd>
          </div>
          <div
            *ngIf="isAdmin"
            (click)="openTemplate(updateMCTemplateRef, 'company_name')"
          >
            <span>
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i
            ></span>
          </div>
        </div>
      </dl>

      <div>
        <dl>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Company Address</dt>
              <dd>{{ mCompanyDetailData?.address?.full_address }}</dd>
            </div>
            <div
              *ngIf="isAdmin"
              (click)="openTemplate(updateMCTemplateRef, 'address')"
            >
              <span>
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i
              ></span>
            </div>
          </div>
        </dl>
      </div>
      <ng-container *ngIf="isAdmin || mCompanyDetailData?.information">
        <div>
          <!--the outer div is necessary for correct padding and matching other div dls-->
          <dl>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Additional Information</dt>
              </div>
              <div class="d-flex align-items-center">
                <span
                  *ngIf="isAdmin"
                  (click)="openTemplate(updateMCTemplateRef, 'information')"
                >
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i>
                </span>
              </div>
            </div>
            <dd class="gt-multi-line">
              {{ mCompanyDetailData?.information }}
            </dd>
          </dl>
        </div>
      </ng-container>
      <dl>
        <div class="d-flex align-items-center mb-2">
          <div class="flex-grow-1">
            <dt>Contacts</dt>
          </div>
          <div class="d-flex align-items-center">
            <span
              role="button"
              *ngIf="isAdmin"
              (click)="openTemplate(updateMCTemplateRef, 'contacts')"
            >
              <i class="fa-solid fa-plus md-font-size text-secondary"></i>
            </span>
          </div>
        </div>

        <dd>
          <table class="table">
            <tbody>
              <tr
                *ngFor="
                  let contact of mCompanyDetailData?.contacts;
                  let i = index
                "
              >
                <td class="ps-3 pt-3 pb-4">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <div class="fw-semibold mb-2">
                        {{ contact?.first_name }} {{ contact?.last_name }}
                      </div>
                      <div class="d-flex mb-2">
                        {{ contact?.email }}
                      </div>
                      <div class="d-flex mb-2" *ngIf="contact?.mobile_number">
                        {{ contact?.mobile_number }}
                      </div>
                      <div>
                        <a href="mailto:{{ contact?.email }}" class="me-2">
                          <button class="me-3 btn btn-sm btn-primary">
                            Email
                          </button>
                        </a>
                        <a href="tel:+{{ contact?.mobile_number }}">
                          <button class="btn btn-sm btn-success">Call</button>
                        </a>
                      </div>
                    </div>

                    <div (click)="removeContact(i)" *ngIf="isAdmin">
                      <i class="fa-regular fa-trash-can md-font-size"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
    </div></div
></ng-container>
}@else {
<div class="fw-bold mb-2">Add New Monitoring Company</div>
<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <form [formGroup]="companyForm">
      <ng-container *ngIf="currentStep === 1">
        <div class="row row-cols-2 gy-2 mb-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-dark btn-lg w-100"
              (click)="redirectToList()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="createMCDisabling()"
              (click)="currentStep = 2"
            >
              Next
            </button>
          </div>
        </div>
        <ng-container
          *ngIf="companyForm.value.company_name && blurCalledDict?.mc"
        >
          <dl class="p-2">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Company Name</dt>
                <dd>
                  {{ companyForm.value.company_name }}
                </dd>
              </div>
              <div>
                <span (click)="editField('mc')">
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i
                ></span>
              </div>
            </div>
          </dl>
        </ng-container>
        <div
          class="input-group my-2"
          *ngIf="!blurCalledDict?.mc || !companyForm.value.company_name"
        >
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              id="mcNameField"
              formControlName="company_name"
              autocomplete="off"
              placeholder="Company Name"
              maxlength="60"
              autofocus
              (blur)="
                companyForm.value.company_name
                  ? onBlurEventTrigger('mc', 'mcNameField')
                  : null
              "
            />
            <label for="company_name">Company Name</label>
          </div>

          <span
            class="input-group-text"
            *ngIf="companyForm.value.company_name"
            (click)="editField('mc')"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <gtapp-address-lookup
          [addressForm]="addressForm"
          placeholder="Lookup Address (Optional)"
        >
        </gtapp-address-lookup>
      </ng-container>
      <ng-container *ngIf="currentStep === 2">
        <div class="row row-cols-2 mb-2">
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-dark btn-lg w-100"
              (click)="currentStep = 1"
            >
              Back
            </button>
          </div>
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              (click)="currentStep = 3"
            >
              Next
            </button>
          </div>
        </div>
        <ng-container
          *ngIf="companyForm.value.information && blurCalledDict?.information"
        >
          <dl class="p-2">
            <!--additionala information-->
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <dt>Additional Information</dt>
              </div>
              <div>
                <span (click)="editField('information')">
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i
                ></span>
              </div>
            </div>
            <dd class="gt-multi-line">
              {{ companyForm.value.information }}
            </dd>
          </dl>
        </ng-container>
        <div
          class="input-group my-2"
          *ngIf="!blurCalledDict?.information || !companyForm.value.information"
        >
          <div class="input-group">
            <div class="col">
              <textarea
                cols="20"
                rows="4"
                wrap="hard"
                class="form-control"
                type="text"
                id="mcInfoField"
                formControlName="information"
                autocomplete="off"
                placeholder="Additional Information (Optional)"
                maxlength="500"
                autofocus
                (blur)="
                  companyForm.value.information
                    ? onBlurEventTrigger('information', 'mcInfoField')
                    : null
                "
              ></textarea>
            </div>

            <span
              class="input-group-text"
              *ngIf="companyForm.value.information"
              (click)="editField('information')"
            >
              <i class="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 3">
        <div class="row row-cols-2 gy-2 mb-4">
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 2"
            >
              Back
            </button>
          </div>
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="createMCDisabling()"
              (click)="createMC()"
            >
              Submit
            </button>
          </div>
        </div>
        <ng-template
          *ngTemplateOutlet="
            contactTemplate;
            context: { contacts: contactDataList }
          "
        ></ng-template>
      </ng-container>
    </form>
  </div>

  <div class="col">
    <div class="d-none d-md-block">
      <ng-container
        *ngIf="addressForm.value?.latitude && addressForm.value?.longitude"
      >
        <gtapp-map-leaflet
          [latLon]="{
            lat: addressForm.value?.latitude,
            lon: addressForm.value?.longitude
          }"
          mapId="companyLocation"
        ></gtapp-map-leaflet>
      </ng-container>
    </div>
  </div>
</div>

}

<ng-template #updateMCTemplateRef let-updateMCTemplateRef="dialogRef" let-data>
  <div class="card dialog-box">
    <div class="card-header">
      Update
      <span class="float-end" (click)="updateMCTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <ng-container [ngSwitch]="data?.field">
        <form [formGroup]="companyForm" appFormEnterAction>
          <ng-container *ngSwitchCase="'company_name'">
            <div class="input-group">
              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  id="mcNameField"
                  formControlName="company_name"
                  autocomplete="off"
                  placeholder="Company Name"
                  maxlength="60"
                  autofocus
                />
                <label for="company_name">Company Name</label>
              </div>

              <span
                class="input-group-text"
                *ngIf="companyForm.value.company_name"
                (click)="editField('mc')"
              >
                <i class="fa-solid fa-xmark"></i>
              </span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'information'">
            <div class="input-group">
              <div class="col">
                <textarea
                  cols="20"
                  rows="4"
                  wrap="hard"
                  class="form-control"
                  type="text"
                  id="mcInfoField"
                  formControlName="information"
                  autocomplete="off"
                  placeholder="Additional Information (Optional)"
                  maxlength="500"
                  autofocus
                ></textarea>
              </div>

              <span
                class="input-group-text"
                *ngIf="companyForm.value.information"
                (click)="editField('information')"
              >
                <i class="fa-solid fa-xmark"></i>
              </span></div
          ></ng-container>
          <ng-container *ngSwitchCase="'address'">
            <gtapp-address-lookup [addressForm]="addressForm">
            </gtapp-address-lookup
          ></ng-container>
        </form>
        <ng-container *ngSwitchCase="'contacts'">
          <div>
            <ng-template
              *ngTemplateOutlet="
                contactTemplate;
                context: { contacts: contactDataList }
              "
            ></ng-template>
          </div>
        </ng-container>
        <div
          class="row g-2 my-3"
          *ngIf="data?.field !== 'contacts' || contactDataList?.length"
        >
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-darkn-light w-100"
              (click)="updateMCTemplateRef.close()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="
                data?.field == 'contacts'
                  ? false
                  : data?.field == 'address'
                  ? !addressForm.valid
                  : !companyForm.valid
              "
              (click)="updateMC(data?.field); updateMCTemplateRef.close()"
            >
              Submit
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #contactTemplate let-contactTemplate="dialogRef" let-data>
  <form
    [formGroup]="contactForm"
    appFormEnterAction
    *ngIf="openContactForm || !contactDataList?.length"
  >
    <div class="row row-cols-1 g-2 mb-4">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="firstName"
          (keyup.enter)="shiftFocus('lastName')"
          formControlName="first_name"
          autocomplete="off"
          [maxlength]="150"
          placeholder="First Name"
        />
        <label for="firstName">First Name</label>
      </div>
      <!-- <div>
            <gtapp-auto-complete
              [data]="contactLookupList"
              placeHolder="First Name"
              searchKeyword="user_full_name"
              [initialValue]="contactForm.value?.first_name"
              [itemTemplate]="userTemplate"
              (inputChanged)="contactLookup($event)"
              (selected)="selectContact($event); shiftFocus('lastName')"
            >
            </gtapp-auto-complete>
          </div>

          <ng-template #userTemplate let-user>
            <div>
              {{ user?.user_full_name }}
              <div
                *ngIf="user?.location_contact_id"
                text="Onsite Contact"
                class="badge"
              ></div>
              <div>
                <small>
                  {{ user?.user_full_name ? user.user_email : "" }}
                  {{
                    user?.user_mobile_number
                      ? " , " + user?.user_mobile_number
                      : ""
                  }}
                </small>
              </div>
            </div>
          </ng-template> -->

      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="lastName"
          (keyup.enter)="shiftFocus('email')"
          formControlName="last_name"
          autocomplete="off"
          [maxlength]="150"
          placeholder="Last Name"
        />
        <label for="lastName">Last Name</label>
      </div>

      <div class="form-floating">
        <input
          class="form-control"
          type="email"
          id="email"
          [class.is-invalid]="
            contactForm.controls['email'].invalid &&
            contactForm.controls['email'].touched
          "
          (keyup.enter)="shiftFocus('phone')"
          formControlName="email"
          autocomplete="off"
          placeholder="Email"
        />
        <label for="email">Email</label>
      </div>
      <div *ngIf="dupContactCheck()">
        <p class="text-danger">Email address already added</p>
      </div>

      <div class="form-floating">
        <input
          class="form-control"
          type="tel"
          minlength="10"
          maxlength="10"
          id="phone"
          formControlName="mobile_number"
          autocomplete="off"
          placeholder="Telephone"
          (input)="mobileNumberCheck()"
        />
        <label for="phone">Telephone</label>
        <div
          *ngIf="
            contactForm.controls['mobile_number'].invalid &&
            contactForm.controls['mobile_number'].touched
          "
        >
          <p class="text-danger">Mobile should be a 10-digit number</p>
        </div>
      </div>
    </div>
    <div class="row g-2 my-3">
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-darkn-light w-100"
          (click)="
            contactForm.reset();
            contactDataList?.length ? (openContactForm = false) : null
          "
        >
          {{ contactDataList?.length ? "Cancel" : "Clear" }}
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          [disabled]="!contactForm.valid || dupContactCheck()"
          (click)="
            contactDataList.push(contactForm.value); openContactForm = false
          "
        >
          Add Contact
        </button>
      </div>
    </div>
  </form>
  <div *ngIf="contactDataList?.length">
    <dl>
      <div class="d-flex align-items-center mb-3">
        <div class="flex-grow-1">
          <dt>Contacts</dt>
        </div>
        <div class="d-flex align-items-center">
          <span
            role="button"
            (click)="openContactForm = true; contactForm.reset()"
          >
            <i class="fa-solid fa-plus md-font-size text-secondary"></i>
          </span>
        </div>
      </div>

      <dd class="p-2">
        <table class="table table-hover">
          <tbody>
            <tr
              *ngFor="let contact of contactDataList; let i = index"
              class="cursor-pointer"
            >
              <td>
                <div class="d-flex">
                  <div class="d-flex align-items-center me-3">
                    <i class="fa-solid fa-address-book fa-2x"></i>
                  </div>
                  <div class="flex-grow-1">
                    {{ contact?.first_name }} {{ contact?.last_name }}
                    <div class="small-font">
                      {{ contact?.email }}
                    </div>
                    <div class="small-font">
                      {{ contact?.mobile_number }}
                    </div>
                  </div>
                  <div (click)="contactDataList?.splice(i, 1)">
                    <i class="fa-regular fa-trash-can md-font-size"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </dd>
    </dl>
  </div>
</ng-template>
